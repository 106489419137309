import { ReactComponent as EmailIcon } from "../assets/emailIcon.svg";
import { ReactComponent as PhoneIcon } from "../assets/phoneIcon.svg";
import { ReactComponent as PinIcon } from "../assets/pinIcon.svg";

import logo from "../assets/modelleaderlogo/8.png";
import { Link } from "react-router-dom";

const ContactSection: React.FC = () => {
  return (
    <div className="bg-[#004AAD] w-full min-h-[60vh] justify-center items-center">
      <div className="grid grid-cols-1 md:grid-cols-2 ">
        <div className="flex flex-col items-center py-10">
          <div className="text-[#fff] m-8 space-y-4">
            <p className="text-5xl">
              Subscribe
              <br />
              to our newsletter
            </p>
            <p className=" text-xl">
              For insights and application on what makes a model leader and how
              you can become one.
            </p>
          </div>
        </div>
        <div className="p-12 flex items-center justify-center">
          <Link
            to={"https://modelleader.substack.com/embed"}
            className="bg-[#ffb552] text-lg font-bold py-6 px-10 rounded-full mt-4 hover:bg-[#AD6300] text-[#004AAD] hover:text-[#fff] shadow-md"
            >
            Subscribe
          </Link>
        </div>
      </div>
      <div className="p-10 bg-[#ececec] rounded-md md:p-24">
        <div className="grid grid-cols-1 md:grid-cols-2 ">
          <div className="flex justify-center">
            <img src={logo} alt="Model Leader Logo" />
          </div>
          <div className="flex justify-center items-center">
            <div className="text-xl text-[#004AAD] space-y-5">
              <h1 className="text-center text-3xl md:text-4xl text-[#003b8a] font-roboto font-thin">
                Contact Us
              </h1>
              <div className="flex flex-col md:flex-row items-center mt-2">
                <EmailIcon className="h-6 fill-[#004AAD]" />
                <a
                  href="mailto:contact@modelleader.com"
                  className="mx-4"
                >
                  contact@modelleader.com
                </a>
              </div>
              <div className="flex flex-col md:flex-row items-center mt-2">
                <PhoneIcon className="h-6 fill-[#004AAD]" />
                <a
                  href="tel:+12409004830"
                  className="mx-4"
                >
                  +1 (240) 900-4830
                </a>
              </div>
              <div className="flex flex-col md:flex-row items-center mt-2">
                <PinIcon className="h-6 fill-[#004AAD]" />
                <p className="mx-4">Based in Washington, D.C.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
